import React from 'react'
import { navigate } from 'gatsby'

import SEO from '../components/SEO'

const NotFoundPage = () => {
  React.useEffect(() => {
    typeof document !== 'undefined' &&
      document.addEventListener('keydown', _recognizeKey, false)

    return () => document.removeEventListener('keydown', _recognizeKey, false)
  })

  function _recognizeKey(event) {
    if (event.code === 'Enter') {
      navigate('/', { state: { showSplash: false } })
    }
  }
  return (
    <div className="bsod">
      <SEO title="404: Not found" />
      <main className="container">
        <h1 className="title">404 - Not Found</h1>
        <p>An error has occurred. To continue:</p>
        <p>Press Enter to return to the Main Page, or</p>
        <p>
          Press CTRL + ALT + DEL again to restart your computer. If you do this,
          you will lose any unsaved information in all open applications.
        </p>
        <p>Error: 0E : 013L : LES4V1GT85</p>
        {
          // <p>Press any key to continue</p>
        }
      </main>
    </div>
  )
}

export default NotFoundPage
